import { Box, Text } from '@chakra-ui/layout';
import { QuestionIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import { useTweetContext } from 'context/tweetContext';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/dist/client/router';
import React from 'react';
import toast from 'react-hot-toast';
import { isAllowed } from '../../controllers/subscription';
import { generate } from '../../controllers/variations';
import { firebaseClient } from '../../firebaseClient';
import { textStyle, variant } from 'theme/names';

export const AiVariationPopup = ({
  isOpen,
  onClose,
  selectedTweet,
  currentTempLevel,
  newTweet,
  onOpenScheduler,
}) => {
  const { data: session, status } = useSession() ?? {};
  const loading = status === 'loading';
  const [isLoadingVariation, setIsLoadingVariation] = React.useState(false);
  const [tempLevel, setTempLevel] = React.useState(3);
  const focusRefVariationAuto =
    React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const router = useRouter();
  const tweetContext: any = useTweetContext();

  React.useEffect(() => {
    setTempLevel(currentTempLevel ?? 0);
  }, [currentTempLevel]);

  return (
    <AlertDialog
      isOpen={isOpen}
      //@ts-ignore
      leastDestructiveRef={focusRefVariationAuto}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Variations Generation</AlertDialogHeader>

          <AlertDialogBody>
            {session?.user?.data?.creditsAuto &&
            session?.user?.data?.creditsAuto > 0 &&
            isAllowed(session?.user, 'ai_variation') ? (
              <>
                <Text textStyle={textStyle['body.medium.light']} mt={2}>
                  Our AI will write an alternative post while trying to stay on
                  the same topic.
                </Text>
                <Box mt={4} display='flex' alignItems='flex-start'>
                  <Text textStyle={textStyle['body.medium.light']}>
                    Choose the freedom you want to give to the AI to come up
                    with new ideas (change this anytime in your Settings).
                  </Text>
                  <Tooltip
                    hasArrow
                    label={
                      'Conservative: will attempt to stay as close as possible to the original post with small variations. Standard: will attempt to stay close to the general idea of the post while offering more impactful variations. Wild: let the AI do its own thing, but you might get a few crazy guesses'
                    }
                  >
                    <QuestionIcon mt={2} color='gray.400' mr={2} />
                  </Tooltip>
                </Box>
                <Flex fontWeight='600' justifyContent='space-between'>
                  <Text textStyle={textStyle['body.medium.light']} mt={4}>
                    Conservative
                  </Text>
                  <Text textStyle={textStyle['body.medium.light']} mt={4}>
                    Wild
                  </Text>
                </Flex>
                <Box pl={6} pr={6}>
                  <Slider
                    mt={5}
                    css={{ marginTop: '-10px' }}
                    width='100%'
                    min={-2}
                    max={2}
                    step={1}
                    aria-label='temp-selector'
                    value={tempLevel}
                    onChange={(val) => {
                      setTempLevel(val);
                    }}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>
                <Checkbox
                  mt={4}
                  onChange={async (e) => {
                    if (session?.user?.uid) {
                      const db = firebaseClient.firestore();
                      await db
                        .collection('users')
                        .doc(session?.user?.uid)
                        .update({
                          skipPopupVariationAuto: e.target.checked,
                        });
                      session.user.data.skipPopupVariationAuto =
                        e.target.checked;
                    }
                  }}
                >
                  Skip this popup next time
                </Checkbox>
              </>
            ) : (
              <Text textStyle={textStyle['body.medium.light']}>
                You can't perform this action, you need to upgrade to a higher
                plan.
              </Text>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} variant='tertiary'>
              Cancel
            </Button>
            {session?.user?.data?.creditsAuto &&
            session?.user?.data?.creditsAuto > 0 &&
            isAllowed(session?.user, 'ai_variation') ? (
              <Button
                isLoading={isLoadingVariation}
                ref={focusRefVariationAuto}
                onClick={async () => {
                  setIsLoadingVariation(true);

                  let newTempLevel = tempLevel ?? 0;

                  if (session?.user?.data) {
                    const db = firebaseClient.firestore();
                    db.collection('users')
                      .doc(session?.user?.uid)
                      .update({ tempLevel: newTempLevel });
                    session.user.data.tempLevel = newTempLevel;
                  }

                  let result = await generate(
                    session,
                    selectedTweet,
                    1,
                    newTempLevel
                  );
                  setIsLoadingVariation(false);

                  if (result?.success && result?.data?.variations?.length) {
                    let newVariation = result?.data?.variations[0];
                    newTweet(
                      { text: newVariation },
                      undefined,
                      true,
                      undefined,
                      () => {
                        tweetContext.setIsTweetTextChanged(true);
                      }
                    );

                    onOpenScheduler();
                    toast.success('Variation pasted in composer');
                  }
                  onClose();
                }}
                variant={variant.Button.primary}
              >
                Generate
              </Button>
            ) : !isAllowed(session?.user, 'ai_variation') ? (
              <Button
                ref={focusRefVariationAuto}
                onClick={() => {
                  router.push('/pricing');
                  onClose();
                }}
                variant={variant.Button.primary}
              >
                Upgrade
              </Button>
            ) : (
              <Box></Box>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
