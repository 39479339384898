import { mode } from '@chakra-ui/theme-tools';

export const colors = {
  neutral: {
    lightMode: {
      neutral: '#FFFFFF',
    },
    darkMode: {
      neutral: '#1E1E1E',
    },
  },
  primary: {
    lightMode: {
      50: '#f4f9ff',
      100: '#E8F3FF',
      200: '#54A9FF',
      default: '#0A66C2',
      400: '#05488B',
      500: '#002E5B',
    },
    darkMode: {
      100: '#1A2C3F',
      200: '#05488B',
      default: '#316BFF',
      400: '#54A9FF',
      500: '#E8F3FF',
    },
  },
  secondary: {
    lightMode: {
      100: '#DEFFF1',
      200: '#9DEBCA',
      default: '#7BC8A8',
      400: '#34AB7A',
      500: '#007645',
    },
    darkMode: {
      100: '#31403A',
      200: '##34AB7A',
      default: '#7BC8A8',
      400: '#9DEBCA',
      500: '#DEFFF1',
    },
  },
  text: {
    lightMode: {
      standard: '#213856',
      light: '#566F8F',
      inactive: '#9E9E9E',
      white: '#FFFFFF',
    },
    darkMode: {
      standard: '#CBCCCD',
      light: '#818181',
      inactive: '#797979',
      white: '#FFFFFF',
    },
  },
  state: {
    lightMode: {
      inactive: '#B7BEC6',
    },
    darkMode: {
      inactive: '#777777',
    },
  },
  info: {
    lightMode: {
      50: '#f4f9ff',
      100: '#E8F3FF',
      200: '#54A9FF',
      default: '#0A66C2',
      400: '#05488B',
      500: '#002E5B',
    },
    darkMode: {
      100: '#1A2C3F',
      200: '#05488B',
      default: '#316BFF',
      400: '#54A9FF',
      500: '#E8F3FF',
    },
  },
  success: {
    lightMode: {
      100: '#E5FCE5',
      200: '#9AE299',
      default: '#31C52E',
      400: '#009E00',
      500: '#007300',
    },
    darkMode: {
      100: '#203220',
      200: '#009E00',
      default: '#31C52E',
      400: '#9AE299',
      500: '#E5FCE5',
    },
  },
  warning: {
    lightMode: {
      100: '#FFF8D3',
      200: '#FFE172',
      default: '#DFB009',
      400: '#D9AA00',
      500: '#916D00',
    },
    darkMode: {
      100: '#453B1A',
      200: '#D9AA00',
      default: '#DFB009',
      400: '#FFE172',
      500: '#FFF8D3',
    },
  },
  alert: {
    lightMode: {
      100: '#FFE7E2',
      200: '#F8B1B1',
      default: '#EC4343',
      400: '#BE0723',
      500: '#8C0000',
    },
    darkMode: {
      100: '#3D2425',
      200: '#BE0723',
      default: '#EC4343',
      400: '#F8B1B1',
      500: '#FFE7E2',
    },
  },
  primaryChakraColorScheme: {
    // https://colorkit.io/shades-tints
    100: '#E7F2FE',
    200: '#AFCFEF',
    300: '#78ACE0',
    400: '#4189D1',
    500: '#0A66C2',
    600: '#074F99',
    700: '#05396E',
    800: '#032343',
    900: '#010D18',
  },
  border: {
    lightMode: {
      inactive: '#B7BEC6',
      light: '#EFEFEF',
      hover: '#B7BEC6',
      active: '#838990',
    },
    darkMode: {
      inactive: '#4F4F4F',
      light: '#2A292E',
      hover: '#838990',
      active: '#838990',
    },
  },
  background: {
    lightMode: {
      light: '#FBFBFB',
      medium: '#F6F6F6',
      disabled: '#F3F6F9',
    },
    darkMode: {
      light: '#1E2023',
      medium: '#1B1B1B',
      disabled: '#23262B',
    },
  },
  green: {
    100: '#E5FCE5',
    400: '#31C52E',
  },
  ai: {
    pink: '#EC6181',
    blue: '#316BFF',
    100: '#F1EDF7',
    200: '#D8CDEA',
    300: '#BDACDC',
    400: '#A28BCE',
    500: '#876AC0',
    600: '#685396',
    700: '#493A6A',
    800: '#2A213E',
    900: '#0B0812',
  },
  taplio: {
    // https://colorkit.io/shades-tints
    0: '#F9F9FD',
    25: '#F3F3FB',
    50: '#ECEDF8',
    100: '#D5D9EE',
    150: '#C2C6E5',
    200: '#AFB3DC',
    250: '#9CA0D3',
    300: '#898DCA',
    350: '#767AC1',
    400: '#6367B8',
    450: '#5054AF',
    500: '#3D41A6',
    550: '#373793',
    600: '#313183',
    650: '#2B2B73',
    700: '#252563',
    750: '#1F1F53',
    800: '#191943',
    850: '#131333',
    900: '#0D0D23',
    950: '#070713',
  },
  taplioAccent: {
    // https://colorkit.io/shades-tints
    50: '#EAF5FB',
    100: '#D7E9F5',
    150: '#C3E0F1',
    200: '#AFD7ED',
    250: '#9BCEE9',
    300: '#87C5E5',
    350: '#73BCE1',
    400: '#5FB3DD',
    450: '#4BAAD9',
    500: '#37A1D5',
    550: '#3490BD',
    600: '#2E80A8',
    650: '#287093',
    700: '#22607E',
    750: '#1C5069',
    800: '#164054',
    850: '#10303F',
    900: '#0A202A',
    950: '#041015',
  },
  zinc: {
    50: '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    725: '#39393F',
    750: '#333338',
    775: '#2D2D31',
    800: '#27272a',
    825: '#232326',
    850: '#1F1F22',
    875: '#1B1B1E',
    900: '#18181b',
  },
  zincAlpha: {
    50: '#71717a11',
    100: '#71717a22',
    200: '#71717a33',
    300: '#71717a44',
    400: '#71717a66',
    500: '#71717a88',
    600: '#71717aaa',
    700: '#71717abb',
    800: '#71717acc',
    900: '#71717add',
  },
  taplioPublic: {
    header: '#263754',
    background: '#F6F7F8',
    border: '#DEDEDE',
    success: '#009E00',
    successBg: '#9AE299',
    error: '#EC4343',
    errorBg: '#F8B1B1',
    footerBg: '#0F1621',
    footerTextMain: '#E5E6EB',
    footerTextSecondary: '#CBCED6',
  },
  taplioOnboarding: {
    background: '#F6F7F8',
    border: '#DEDEDE',
    bodyText: '#566F8F',
    headingText: '#213856',
  },
  taplioDashboard: {
    neutral: '#F6F6F6',
    red: '#EC6181',
    orange: '#FFB992',
    yellow: '#FFE172',
    green: '#9AE198',
    blue: '#134CDD',
    bronze: '#AE633E',
    silver: '#ABACAC',
    gold: '#FFB200',
    platinum: '#A478E3',
    diamond: '#31C52E',
  },
};
