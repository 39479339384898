import * as analytics from "../utils/analytics";
import toast from "react-hot-toast";
import { getAccount, updateUser, showRefreshTokenToast } from "../utils/sessionHelper";
import { firebaseClient, getToken } from "firebaseClient";

// standard => grow
// pro => enterprise

let plans = {
    "prod_NKAbUYeT4CBXu7": "starter", //live starter monthly
    "prod_NKAcCCLXdjn8ih": "starter", //live starter yearly
    "prod_KIYrvwkEn7QXbf": "grow", // live grow monthly
    "prod_MwTvOxBEaw1CPU": "grow", // live grow yearly
    "prod_KO95adewUc7phe": "grow", // dev
    "prod_JtR2GTlQmSXyyP": "grow", // dev
    "prod_NECVUOpjEd3ngp": "enterprise", // dev
    "prod_NECXvheOKxm1Yc": "enterprise", // dev
}

let monthlyPrices = [
    // "price_1OeJivKB4y5X3SSh8SQ6iRwA", // starter live
    "price_1PY3sMKB4y5X3SShqQi73n9y", // starter live 39 monthly - prod_NKAbUYeT4CBXu7 - Starter 06/2024
    // "price_1PY45mKB4y5X3SSh1Ft9CBcI", //starter live 384 yearly - prod_NKAcCCLXdjn8ih - Starter yearly - 06/2024
    "price_1Jdxk1KB4y5X3SShvC5pCuEq", // grow live
    "price_1OeJjdKB4y5X3SShvHwgxQA9", // grow live
    "price_1JjMnDKB4y5X3SSh7AVScCkC", // grow dev
    "price_1MTk6dKB4y5X3SShLjkchBZM", // enterprise live
    "price_1NIS5YKB4y5X3SShF6Wh7zYg", // enterprise live
]

let featureGrow = [
    "variation",
    "generate",
    "ai_variation",
    "hook",
    "idea",
    "crm",
    "replyGeneration",
    "icebreaker",
    // "ghostwriting",
    // "engage",
];

let featureEnterprise = [
    "an awesome feature that doesn't exist yet"
];

export const useCredit = async (
    session,
    mainContext,
    creditKey = "creditsAi",
    showUpgradeOnFail = true,
    creditsUsed = 1
) => {
  const userHasCredit =
    checkCredit(session, mainContext, creditKey, showUpgradeOnFail) === true;
  const forceUseFastModel = session?.user?.data?.forceUseFastModel ?? false;

  // forceUseFastModel means user is using unlimited GPT-4o-mini so we skip the credit consumption part
  if (forceUseFastModel && creditKey === 'creditsBestai') {
    return false;
  }

  if (userHasCredit) {
    let newValue = session.user.data[creditKey] - creditsUsed;
    if (newValue < 0) {
      newValue = 0;
    }
    let dataToSave = { [creditKey]: newValue };

    await updateUser(session, dataToSave); // update the session

    return true;
  } else {
    // for creditsBestai, show modal that tells the user he is out of GPT-4o credits, but show it only once by using the variable disableShowNoMoreBestCredits
    if (creditKey === 'creditsBestai') {
      // get value of disableShowNoMoreBestCredits
      const disableShowNoMoreBestCredits =
        session?.user?.data?.disableShowNoMoreBestCredits ?? false;
      // show modal if not disableShowNoMoreBestCredits
      if (!disableShowNoMoreBestCredits) {
        mainContext.onOpenBestAiOutOfCredits();
        // set disableShowNoMoreBestCredits to true
        await updateUser(session, { disableShowNoMoreBestCredits: true });
      }
    }

    // normal case
    return false;
  }
}

export const checkCredit = (
    session,
    mainContext,
    creditKey = "creditsAi",
    showUpgradeOnFail = false
) => {
    if (session?.user?.data?.subscription?.isSubscribed
        && session?.user?.data?.[creditKey] > 0
    ) {
        return true;
    } else {
        if (showUpgradeOnFail) {
            let isEnterprise = getAccount(session)?.subscription?.plan === "enterprise";
            if (!isEnterprise && mainContext?.onOpenUpgrade && creditKey === "creditsReplies") // can check using !session?.user?.data?.creditsReplies too. 
                mainContext.onOpenUpgrade("You used all your reply credits (reset the 1st of each month) - upgrade to get more.");
            else if (!isEnterprise && mainContext?.onOpenUpgrade)
                mainContext.onOpenUpgrade("You used all your AI credits (reset daily) - upgrade to get more.");
            else if (!isEnterprise)
                toast.error("You used all your AI credits - upgrade to get more.");
            else
                toast.error("You used all your AI credits.");
        }

        return false
    }
}

export const getCreditBack = async (session, creditKey = "creditsAi", numbersOfCredit = 1) => {
    let newValue = session.user.data[creditKey] + numbersOfCredit;
    let dataToSave = { [creditKey]: newValue };
    await updateUser(session, dataToSave); // update the session
    return true;
}

export const getPeriodicity = (user) => {

    if (user?.data?.subscription?.isSubscribed && (!user?.data?.subscription?.price || monthlyPrices.includes(user.data.subscription.price)))
        return "monthly";
    else
        return "yearly";
}

export const getPlan = (user) => {
    return user?.data?.subscription?.plan || "";
    if (user?.data?.subscription?.isSubscribed && !user?.data?.subscription?.product)
        return "grow";
    else if (user?.data?.subscription?.isSubscribed && user?.data?.subscription?.product && plans[user.data.subscription.product])
        return plans[user.data.subscription.product];
    else
        return "";
}

export const isAllowed = (user, feature) => {

    let plan = getPlan(user);
    // console.log("plan: " + plan);
    // console.log("isSubscribed: " + user?.data?.subscription?.isSubscribed);

    if (["enterprise"].includes(plan))
        return true;
    else if (featureEnterprise.includes(feature))
        return false;
    else if (["grow"].includes(plan))
        return true;
    else if (featureGrow.includes(feature))
        return false;
    else if (!user?.data?.subscription?.isSubscribed)
        return false;
    else
        return true;
}

export const resumeSubscription = async (session, setLoading, refresher = undefined as any) => {
    if (!session?.user?.data?.subscription?.subscription) {
        
        toast.error("subscription to update not found, please contact us");
        return;
    }

    let data = {
        isLocal: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        subscription: session.user.data.subscription.subscription,
        userId: session.user.uid,
    };

    setLoading(true);

    const response = await fetch("/api/subscriptionResume", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json", token: "" }),
        credentials: "same-origin",
        body: JSON.stringify(data),
    });
    const stripeUpdate = await response.json();

    setLoading(false);

    if (stripeUpdate.success) {
      toast.success('Subscription resumed successfully');
      session.user.data.subStatus == 'FreeTrial_CancelRequest'
        ? 'FreeTrial'
        : 'Subscribed';

      refresher && refresher({});
    } else {
      toast.error('Failed to resume subscription please contact us');
    }
}