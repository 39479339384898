export const ez4castBaseUrl = {
  devEurope: 'http://127.0.0.1:5001/ez4cast/europe-west1/',
  devUS: 'http://127.0.0.1:5001/ez4cast/us-central1/',
  prod: 'https://us-central1-ez4cast.cloudfunctions.net/',
  //prod eu? needs to implemented in the base url
};

export const ez4castFeatureEndpoints = {
  generateSmartReplies: 'linkedinReplies-generateReplies',
  postGeneratorSuggestTopics: 'suggestTopics-suggestTopics',
  postGeneratorGeneratePost: 'linkedinPostGenerator-generatePosts',
  postGeneratorGeneratePostOld:
    'linkedinGenerationOnDemandV3-generateLinkedinPostv3',
  linkedinAutoCompleteSearch: 'linkedinAutoComplete-search',
  tweetSchedulerTweetNow: 'tweetScheduler-tweetNow',
  fetchSsiScoreByUser: 'linkedinFetcher-fetchSsiScoreByUser',
};

export const ez4castApiUrls = {
  generateSmartReplies: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.generateSmartReplies}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.generateSmartReplies}`,
  },
  postGeneratorSuggestTopics: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.postGeneratorSuggestTopics}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.postGeneratorSuggestTopics}`,
  },
  postGeneratorGeneratePost: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.postGeneratorGeneratePost}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.postGeneratorGeneratePost}`,
  },
  postGeneratorGeneratePostOld: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.postGeneratorGeneratePostOld}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.postGeneratorGeneratePostOld}`,
  },
  linkedinAutoCompleteSearch: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.linkedinAutoCompleteSearch}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.linkedinAutoCompleteSearch}`,
  },
  tweetSchedulerTweetNow: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.tweetSchedulerTweetNow}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.tweetSchedulerTweetNow}`,
  },
  fetchSsiScoreByUser: {
    dev: `${ez4castBaseUrl.devUS}${ez4castFeatureEndpoints.fetchSsiScoreByUser}`,
    prod: `${ez4castBaseUrl.prod}${ez4castFeatureEndpoints.fetchSsiScoreByUser}`,
  },
};
