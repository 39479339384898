import React, { useState } from "react";
import {
  Button,
  Input,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Icon,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Box,
  InputGroup,
  InputRightElement,
  Textarea,
  HStack,
} from "@chakra-ui/react";
import { FaTrash, FaTimes, FaPlus } from "react-icons/fa";
import { variant } from "theme/names";

interface PollModalProps {
  isOpen: boolean;
  onClose: () => void;
  pollQuestion: string;
  setPollQuestion: (value: string) => void;
  pollOptions: string[];
  setPollOptions: (options: string[]) => void;
  pollDuration: string;
  setPollDuration: (value: string) => void;
  onSave: () => void;
  isEditing?: boolean;
}

const PollModal: React.FC<PollModalProps> = ({
  isOpen,
  onClose,
  pollQuestion,
  setPollQuestion,
  pollOptions,
  setPollOptions,
  pollDuration,
  setPollDuration,
  onSave,
  isEditing = false,
}) => {
  const MAX_QUESTION_LENGTH = 140;

  const handleAddPollOption = () => {
    if (pollOptions.length < 4) {
      setPollOptions([...pollOptions, ""]);
    }
  };

  const handlePollOptionChange = (index: number, value: string) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const handleRemovePollOption = (index: number) => {
    if (pollOptions.length > 2) {
      const newOptions = pollOptions.filter((_, i) => i !== index);
      setPollOptions(newOptions);
    }
  };

  const clearOption = (index: number) => {
    const newOptions = [...pollOptions];
    newOptions[index] = "";
    setPollOptions(newOptions);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader fontSize="xl" fontWeight="semibold">{isEditing ? "Edit poll" : "Create a poll"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel fontSize="xs">Survey duration</FormLabel>
              <Select
                value={pollDuration}
                onChange={(e) => setPollDuration(e.target.value)}
              >
                <option value="ONE_DAY">1 Day</option>
                <option value="THREE_DAYS">3 Days</option>
                <option value="ONE_WEEK">1 Week</option>
                <option value="TWO_WEEKS">2 Weeks</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="xs">Your question</FormLabel>
              <Textarea
                placeholder="Your question"
                value={pollQuestion}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_QUESTION_LENGTH) {
                    setPollQuestion(e.target.value);
                  }
                }}
                resize="none"
                h="100px"
              />
              <Text textAlign="right" fontSize="xs" color="gray.500">
                {pollQuestion.length}/{MAX_QUESTION_LENGTH}
              </Text>
            </FormControl>

            {pollOptions.map((option, index) => (
              <FormControl key={index} >
                <FormLabel fontSize="xs">Option {index + 1}{index < 2 ? "*" : ""}</FormLabel>
                <HStack alignItems="center">
                  <InputGroup>
                    <Input
                      placeholder={`Answer ${index + 1}`}
                      value={option}
                      onChange={(e) => handlePollOptionChange(index, e.target.value)}
                    />
                    <InputRightElement>
                      {option && (
                        <IconButton
                          aria-label="Clear option"
                          icon={<Icon as={FaTimes} />}
                          size="sm"
                          variant="ghost"
                          onClick={() => clearOption(index)}
                          borderRadius="full"
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {index >= 2 && (
                    <IconButton
                      aria-label="Remove option"
                      icon={<Icon as={FaTrash} />}
                      variant="action"
                      onClick={() => handleRemovePollOption(index)}

                    />
                  )}
                </HStack>
              </FormControl>
            ))}

            {pollOptions.length < 4 && (
              <Button
                onClick={handleAddPollOption}
                size="sm"
                variant="tertiary"
                alignSelf="flex-start"
                leftIcon={<Icon as={FaPlus} />}
              >
                Add Option
              </Button>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            variant={variant.Button.primary}
            onClick={onSave}
            isDisabled={
              !pollQuestion ||
              pollOptions.length < 2 ||
              pollOptions.some(option => !option.trim())
            }
            borderRadius="md"
            px={6}
          >
            {isEditing ? "Save Edits" : "Add this poll"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PollModal; 