import React, { createContext, useContext, useEffect } from "react";
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Text,
  Link,
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/dist/client/router";
import { GetExtension } from "components/popups/getExtension";
import { textStyle, variant } from "theme/names";
import NextLink from "next/link";
import { getPlan } from "controllers/subscription";

export const MainContext = createContext({});

const BestAiOutOfCredits = ({
  isOpenBestAiOutOfCredits,
  onCloseBestAiOutOfCredits,
  onOpenBestAiOutOfCredits,
}) => {
  const router = useRouter();
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
 // const plan = "grow"
  const plan = getPlan(session?.user);

  const modalBody = {
    grow: (
      <ModalBody>
        <Text textStyle={textStyle['body.medium.light']}>
          As a Standard plan subscriber, you can use up to 250 GPT-4o credits
          per month. GPT-4o is the best AI model commercially available and
          helps you create high quality content easily.
        </Text>
        <Text textStyle={textStyle['body.medium.light']} mt={4}>
          <Link
            as={NextLink}
            textDecoration='underline'
            textUnderlineOffset={'3px'}
            href='/pricing'
            fontWeight={600}
            onClick={onCloseBestAiOutOfCredits}
          >
            Upgrade to the Pro plan
          </Link>{' '}
          and get an immediate top off + 5,000 GPT-4o credits per month.
        </Text>
        <Text textStyle={textStyle['body.medium.light']} mt={4}>
          You can also keep your current Standard plan and be downgraded to
          GPT-4o-mini, a very good but inferior AI model until the 1st of next
          month when your credits will be reset.
        </Text>{' '}
      </ModalBody>
    ),
    enterprise: (
      <ModalBody>
        <Text textStyle={textStyle['body.medium.light']}>
          As a Pro plan subscriber, you can use up to 5,000 GPT-4o credits per
          month. GPT-4o is the best AI model commercially available and helps
          you create high quality content easily.
        </Text>
        <Text textStyle={textStyle['body.medium.light']} mt={4}>
          Unfortunately, you have run out of credits. You will now be switched
          over to GPT-4o-mini, which is a very capable AI model but slightly
          inferior to GPT-4o.
        </Text>
      </ModalBody>
    ),
  };

  const modalFooter = {
    grow: (
      <ModalFooter>
        <Button
          variant='tertiary'
          onClick={async () => {
            router.push('/pricing');
            onCloseBestAiOutOfCredits();
          }}
        >
          🚀 Upgrade to Pro
        </Button>
        <Button
          onClick={() => {
            onCloseBestAiOutOfCredits();
          }}
          variant={variant.Button.primary}
        >
          Move to GPT-4o-mini
        </Button>
      </ModalFooter>
    ),
    enterprise: (
      <ModalFooter>
        <Button
          variant='tertiary'
          onClick={() => {
            // TODO: update with info page when exists
            router.push('/pricing');
            onCloseBestAiOutOfCredits();
          }}
        >
          Learn more about AI credits
        </Button>
        <Button
          onClick={() => {
            onCloseBestAiOutOfCredits();
          }}
          variant={variant.Button.primary}
        >
          OK
        </Button>
      </ModalFooter>
    ),
  };

  return (
    <Modal
      size='xl'
      isOpen={isOpenBestAiOutOfCredits}
      onClose={onCloseBestAiOutOfCredits}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {/* <ModalInfoIcon /> */}
          You have run out of GPT-4o credits this month 🥲
        </ModalHeader>
        <ModalCloseButton />
        {modalBody[plan]}
        {modalFooter[plan]}
      </ModalContent>
    </Modal>
  );
};

export function MainWrapper({ children }) {
  const {
    isOpen: isOpenUpgrade,
    onOpen: onOpenUpgradeInternal,
    onClose: onCloseUpgrade,
  } = useDisclosure();
  const {
    isOpen: isOpenGetExtension,
    onOpen: onOpenGetExtension,
    onClose: onCloseGetExtension,
  } = useDisclosure();
  const {
    isOpen: isOpenBestAiOutOfCredits,
    onOpen: onOpenBestAiOutOfCredits,
    onClose: onCloseBestAiOutOfCredits,
  } = useDisclosure();
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const defaultUpgradeMessage =
    "This feature belongs to a higher plan. Upgrade your account to grow even faster.";
  const [upgradeMessage, setUpgradeMessage] = React.useState(
    defaultUpgradeMessage
  );

  const onOpenUpgrade = function (customMessage = undefined) {
    setUpgradeMessage(customMessage ?? defaultUpgradeMessage);
    onOpenUpgradeInternal();
  };

  const router = useRouter();

  let sharedState = {
    onOpenUpgrade: onOpenUpgrade,
    onCloseUpgrade: onCloseUpgrade,
    onOpenGetExtension: onOpenGetExtension,
    onCloseGetExtension: onCloseGetExtension,
    onOpenBestAiOutOfCredits: onOpenBestAiOutOfCredits,
  };

  return (
    <MainContext.Provider value={sharedState}>
      {children}
      <Modal isOpen={isOpenUpgrade} onClose={onCloseUpgrade}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {/* <ModalInfoIcon /> */}
            Time to upgrade
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{upgradeMessage}</ModalBody>

          <ModalFooter p={3}>
            <Button
              // colorScheme="blue"
              onClick={() => {
                router.push("/pricing");
                onCloseUpgrade();
              }}
              variant={variant.Button.primary}
            >
              Upgrade
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <GetExtension isOpen={isOpenGetExtension} onClose={onCloseGetExtension} />
      <BestAiOutOfCredits
        // isOpenBestAiOutOfCredits={true}
        isOpenBestAiOutOfCredits={isOpenBestAiOutOfCredits}
        onCloseBestAiOutOfCredits={onCloseBestAiOutOfCredits}
        onOpenBestAiOutOfCredits={onOpenBestAiOutOfCredits}
        // push={router.push}
      ></BestAiOutOfCredits>
      </MainContext.Provider>
    );
}

export function useMainContext() {
  return useContext(MainContext);
}
